<template>
  <v-card>
    <v-card-title class="px-2">
      <Comeback /> {{ $t('Insprction Report') }} <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :loading="exportLoading"
        class="d-none d-md-block"
        :disabled="exportLoading"
        @click="exportExcel"
      >
        {{ $t('excel') }}
      </v-btn>
      <v-btn
        color="primary"
        class="d-block d-md-none"
        :loading="exportLoading"
        :disabled="exportLoading"
        icon
        fab
        outlined
        @click="exportExcel"
      >
        <v-icon>{{ mdiFileExcelOutline }}</v-icon>
      </v-btn>
    </v-card-title>
    <DateFilters @onSendDate="addPayload" />
    <v-row class="px-2">
      <v-col cols="12" md="5" lg="3" class="py-0">
        <v-select
          v-model.trim="statusSelected"
          :items="statusList"
          dense
          outlined
          :label="$t('status')"
          :item-text="$i18n.locale"
          item-value="value"
          @change="addPayload(payload.start, payload.end)"
        ></v-select>
      </v-col>
      <v-col cols="12" md="5" lg="3" class="py-0">
        <v-select
          v-model.trim="opdupload_type"
          @change="addPayload(payload.start, payload.end)"
          class="set-text"
          :items="imageStatusData"
          :label="$t('picture_status')"
          outlined
          dense
          :item-text="$i18n.locale"
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      :loading="loading"
      disable-sort
      hide-default-footer
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.opd_code`]="{ item }">
        <router-link
          v-if="item.opd_id"
          :to="{ name: 'ShowDetailCheck', params: { id: item.opd_id } }"
          class="font-weight-medium text-decoration-none"
          target="_blank"
        >
          {{ item.opd_code }}
        </router-link>
      </template>

      <template v-slot:[`item.opdchecking_name`]="{ item }">
        <span :class="item.opdchecking_name == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.opdchecking_name }}
        </span>
      </template>
      <template v-slot:[`item.opdchecking_fee`]="{ item }">
        <span :class="item.opdchecking_name == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.opdchecking_fee }}
        </span>
      </template>
      <template v-slot:[`item.opdchecking_price`]="{ item }">
        <span :class="item.opdchecking_name == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.opdchecking_price }}
        </span>
      </template>
      <template v-slot:[`item.opdchecking_discount`]="{ item }">
        <span :class="item.opdchecking_name == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.opdchecking_discount }}
        </span>
      </template>
      <template v-slot:[`item.opdchecking_cost`]="{ item }">
        <span :class="item.opdchecking_name == $t('total') ? 'font-weight-bold primary--text' : ''">
          {{ item.opdchecking_cost }}
        </span>
      </template>
      <template v-slot:[`item.opd_comment`]="{ item }">
        <span v-html="item.opd_comment"></span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportCheck, removeComma } from '../useExcel'
import Comeback from '../Comeback.vue'
import { sumdate } from '@/plugins/filters'
import opd_checking_status from '@/@fake-db/data/opd_checking_status.json'
import opd_upload_status from '@/@fake-db/data/opd_upload_status.json'
export default {
  components: {
    DateFilters,
    Comeback,
  },
  setup() {
    const statusList = ref(opd_checking_status.data)
    const statusSelected = ref('')
    const XLSX = require('xlsx')
    const payload = ref({})
    const loading = ref(false)
    const exportLoading = ref(false)
    const options = ref({})
    const dataTableList = ref([])
    const opdupload_type = ref(opd_upload_status.data[0].value)
    const imageStatusData = ref(opd_upload_status.data)
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('a_number'), value: 'opd_code', width: 120 },
      { text: i18n.t('code'), value: 'customer_id', width: 100 },
      { text: i18n.t('customer_patient'), value: 'customer_fullname', width: 220 },
      { text: i18n.t('doctor_appointment'), value: 'user_fullname', width: 220 },
      { text: i18n.t('date_of_appointment'), value: 'opd_date', width: 170 },
      { text: i18n.t('status'), value: 'opd_status_name', width: 140 },
      { text: i18n.t('examination_code'), value: 'opdchecking_code', width: 140 },
      { text: i18n.t('Check list'), value: 'opdchecking_name', width: 140 },
      { text: 'CC', value: 'opd_cc', width: 140 },
      { text: 'HPI', value: 'opd_hpi', width: 140 },
      { text: 'PMH', value: 'opd_pmh', width: 140 },
      { text: 'PE', value: 'opd_pe', width: 140 },
      { text: 'GA', value: 'opd_ga', width: 140 },
      { text: 'DX', value: 'opd_dx', width: 140 },
      {
        text: i18n.t('doctor_fee'),
        value: 'opdchecking_fee',
        width: 180,
        align: 'end',
      },
      {
        text: i18n.t('opdchecking_price'),
        value: 'opdchecking_price',
        width: 180,
        align: 'end',
      },
      {
        text: i18n.t('opdchecking_discount'),
        value: 'opdchecking_discount',
        width: 180,
        align: 'end',
      },
      {
        text: i18n.t('commissions'),
        value: 'opdchecking_cost',
        width: 180,
        align: 'end',
      },
      { text: i18n.t('note'), value: 'opd_comment', width: 180 },
    ])

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        opd_status_id: statusSelected.value,
        image_status: opdupload_type.value,
        lang: i18n.locale,
      }
      searchReport(payload.value)
    }

    const searchReport = async payload => {
      loading.value = true
      const { data } = await reportCheck(payload)
      dataTableList.value = data
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const mapData = data => {
      data = data.map(item => {
        item.customer_tags = `${item.customer_tags ? item.customer_tags : ''}`
        delete item.opd_id
        if (item.opd_comment) {
          item.opd_comment = item.opd_comment
            .replaceAll('<p>', '')
            .replaceAll('</p>', '')
            .replaceAll('<br>', '\n')
            .replaceAll('</br>', '')
            .replaceAll('<ul>', '')
            .replaceAll('</ul>', '')
            .replaceAll('<li>', '')
            .replaceAll('</li>', '\n')
            .replaceAll('<ol>', '')
            .replaceAll('</ol>', '')
        } else {
          item.opd_comment = item.opd_comment
        }
        return item
      })

      return data
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const a = mapData(dataTableList.value)
      const { start, end } = payload.value
      const fileName = `${i18n.t('InspectionReport')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(
        end,
      )}.xlsx`

      //* เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx *//
      /*  รายงานวิเคราะห์รายการตรวจ */
      const dataExport = await removeComma(JSON.parse(JSON.stringify(a)))

      const Heading = [
        [`${i18n.t('InspectionReport')} `],
        [
          '#',
          `${i18n.t('a_number')}`,
          `${i18n.t('code')}`,
          `${i18n.t('customer_patient')}`,
          `${i18n.t('doctor_appointment')}`,
          `${i18n.t('date_of_appointment')}`,
          `${i18n.t('status')}`,
          `${i18n.t('examination_code')}`,
          `${i18n.t('Check list')}`,
          `CC`,
          `HPI`,
          `PMH`,
          `PE`,
          `GA`,
          `DX`,
          `${i18n.t('examination_list')}`,
          `${i18n.t('doctor_fee')}`,
          `${i18n.t('opdchecking_price')}`,
          `${i18n.t('opdchecking_discount')}`,
          `${i18n.t('commissions')}`,
          `${i18n.t('note')}`,
          `${i18n.t('tag')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'opd_code',
          'customer_id',
          'customer_fullname',
          'user_fullname',
          'opd_date',
          'opd_status_name',
          'opdchecking_code',
          'opdchecking_name',
          'opd_cc',
          'opd_hpi',
          'opd_pmh',
          'opd_pe',
          'opd_ga',
          'opd_dx',
          'opdchecking_name',
          'opdchecking_fee',
          'opdchecking_price',
          'opdchecking_discount',
          'opdchecking_cost',
          'opd_comment',
          'customer_tags',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }]
      const wscols = [
        { wch: 8 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 18 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, `${i18n.t('InspectionReport')}`)

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      statusList,
      statusSelected,
      XLSX,
      payload,
      loading,
      exportLoading,
      options,
      dataTableList,
      columns,
      addPayload,
      exportExcel,
      imageStatusData,
      opdupload_type,
      mdiFileExcelOutline,
    }
  },
}
</script>
